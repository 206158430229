<template>
    <div class="service-form py-4">
        <form @submit.prevent="priceCalc = true" class="service-form-wrap container justify-content-center text-center">
            <div class="location-form form-group row justify-content-center align-bottom">
                <div class="city col-12 col-md-4 text-md-left">
                    <h6>1. ИЗБЕРИ КЪДЕ:</h6>
                    <select v-model="selectedCity" class="custom-select custom-select-md mb-3" size="1" required oninvalid="this.setCustomValidity('Моля изберете град.')" oninput="this.setCustomValidity('')">
                        <option v-for="(city, value, index) in citiesList" :key="index" :value="city" :selected="selectedCity.name">{{ city.name }}</option>
                    </select>
                </div>
                <div class="district col-12 col-md-4">
                    <h6><br></h6>
                    <select v-model="selectedDistrict" class="custom-select custom-select-md mb-3" size="1" oninvalid="this.setCustomValidity('Моля изберете квартал.')" oninput="this.setCustomValidity('')">
                        <option v-for="(obj, dist, num) in selectedCity.district" :key="num" :value="obj" :selected="selectedDistrict">{{ obj }}</option>
                    </select>
                </div>
            </div>
            <div class="location-form form-group row mt-3 justify-content-center align-bottom">
                <div class="dropdown when col-12 col-md-4 text-md-left">
                    <h6>2. ИЗБЕРИ КОГА:</h6>
                    <button class="btn btn-light dropdown-toggle w-100 text-md-left" 
                    type="button" id="dropdownMenuButton" 
                    data-toggle="dropdown" 
                    aria-haspopup="true" aria-expanded="false">
                    {{ dateSelected }}
                    </button>
                    <div class="dropdown-menu time-picker" aria-labelledby="dropdownMenuButton">
                        <p class="dropdown-item" @click="dateSelected = 'Всички възможности'">Всички възможности</p>
                        <p class="dropdown-item" @click="dateSelected = 'В следващите 4 дни'">В следващите 4 дни</p>
                        <Datepicker class="text-center" v-model="picked" :lowerLimit="todaysDate" @input="modelValue" :inputFormat="'dd-MM-yyyy'" />
                    </div>
                </div>
                <div class="dropdown how-much col-12 col-md-4 mt-3 mt-md-0 text-md-left">
                    <h6>3. ИЗБЕРИ КОЛКО:</h6>
                    <div class="input-group mb-2">
                        <input type="text" 
                        class="form-control" 
                        id="inlineFormInputGroup" 
                        placeholder="Въведи площ"
                        v-model="meters"
                        pattern = "\d{1,5}" 
                        title='Възможни са само цифри за това поле.' 
                        required 
                        oninvalid="this.setCustomValidity('Моля изберете площ.')" 
                        oninput="this.setCustomValidity('')">
                        <div class="input-group-prepend">
                            <div class="input-group-text">м²</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="location-form form-group row mt-3 justify-content-center align-bottom">
                <button type="submit" class="btn btn-danger">Виж цена</button>
            </div>
        </form>
        <EmailForm v-if="priceCalc" :price="price" :formText="formText" :city="selectedCity.name" :district="selectedDistrict" :date="dateSelected" :meters="meters" @close="priceCalc = false" />
    </div>
</template>
<script>
    import Datepicker from 'vue3-datepicker'
    import { ref } from 'vue'
    import moment from 'moment';
    import EmailForm from './includes/services/includes/EmailForm'
    import { cities } from '../cities'

    export default {
        name: 'ServiceForm',
        props: {
            modelValue: Date,
            servicePrice: Number,
        },
        data() {
            return {
                citiesList: [],
                selectedCity: { name: 'Пловдив', district: ['Белащица', 'Беломорски', 'Бенковски', 'Браниполе', 'Брестник', 'Войводино', 'Гаганица', 'Гагарин', 'Захарна Фабрика', 'Златитрап', 'Изгрев', 'Кадиево', 'Каменица', 'Коматево', 'Костиево', 'Крумово', 'Кършияка', 'Марково', 'Младежки хълм', 'Оризари', 'Остромила', 'Прослав', 'Първенец', 'Радиново', 'Рогош', 'Скутаре', 'Столипиново', 'Съдийски', 'Тракия', 'Филипово', 'Христо Смирненски', 'Център', 'Южен'] },
                selectedDistrict: 'Център',
                picked: ref(new Date()),
                dateSelected: 'Всички възможности',
                todaysDate: ref(new Date()),
                priceCalc: false,
                meters: null,
                price: 0,
                formText: `Нова Заявка на стойност: `,
            }
        },
        components: { 
            Datepicker,
            EmailForm,
        },
        mounted() {
            this.getCities(); 
        },
        watch: {
            picked (oldValue) {
                this.dateSelected = this.formatDate(oldValue);
            },
            meters (newValue) {
                this.price = newValue * this.servicePrice;
            },
         //   selectedCity (newValue) {
           //     this.price = (newValue.cost) + (this.servicePrice) * (this.meters);
            //}
        },
        methods: {
            formatDate(value) {
                return moment(String(value)).format('DD-MM-YYYY')
            },
            getCities() {
                this.citiesList = cities;
            }
        }
    }
</script>