<template>
    <div class="email-form container mt-5 justify-content-center text-center">
        <transition name="fade" mode="out-in">
            <div v-if="sent">
                <h4>Заявката Ви е изпратена.</h4>
            </div>
        </transition>
        <div v-if="!sent" class="form-group container">
            <form @submit="onSubmit" class="row justify-content-center">
                <div class="price-border col-12 col-md-4 py-3 border border-white rounded">
                    <input class="form-control mb-3" 
                    id="inlineFormInputGroup" 
                    placeholder="Телефон за контакт"
                    v-model="form.phoneNumber"
                    pattern = "\d{10,10}" 
                    title='Възможни са само цифри за това поле.' 
                    required 
                    oninvalid="this.setCustomValidity('Моля въведете телефонния си номер - 10 знака.')" 
                    oninput="this.setCustomValidity('')">
                    <input class="form-control mb-3" 
                    id="inlineFormInputGroup" 
                    placeholder="Имена"
                    v-model="form.names" 
                    required 
                    oninvalid="this.setCustomValidity('Моля въведете имената си.')" 
                    oninput="this.setCustomValidity('')">
                    <h4 class="d-inline-block my-0 mx-3 align-middle">{{ price }} лв.</h4><h5>{{ addCosts }}</h5>
                    <button class="btn btn-danger d-inline-block mx-3 align-middle" type="submit">Заяви</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
const querystring = require("querystring");
import axios from 'axios';

export default {
    name: 'EmailForm',
    props: {
        price: String,
        formText: String,
        city: String,
        district: String,
        date: Date,
        meters: Number,
    },
    data() {
        return {
          sent: false,
          form: {
              phoneNumber: '',
              names: '',
              text: `<p>${this.formText} ${this.price} лв. (площ: ${this.meters} кв.м.)</p><p>За дата и място: ${this.date} в гр.${this.city}, кв.${this.district}</p>`,
              title: 'Нова заявка от cleaner.bg',
          },
          addCosts: '',
        }
    },
    methods: {
        onSubmit(e) {
            e.preventDefault();
            this.$emit('close');
            axios
                .post(
                    "https://cleaner.bg/mail.php",
                    querystring.stringify(this.form)
                )
                // eslint-disable-next-line no-unused-vars
                .then(res => {
                    this.sent = true;
                });
        },
        // eslint-disable-next-line no-unused-vars
        close(event) {
            this.$emit('close');
        }
    },
    mounted() {
        if(this.city == 'Пловдив' && this.district == 'Център') {
                this.addCosts = ''
            } else {
                this.addCosts = '+ транспортни разходи'
            }
    },
    watch: {
        city() {
            if(this.city == 'Пловдив' && (this.district == 'Център' || this.district == 'Беломорски' || this.district == 'Гагарин' || this.district == 'Захарна Фабрика' || this.district == 'Изгрев' || this.district == 'Каменица' || this.district == 'Коматево' || this.district == 'Киршияка' || this.district == 'Младежки хълм' || this.district == 'Остромила' || this.district == 'Прослав' || this.district == 'Столипиново' || this.district == 'Съдийски' || this.district == 'Тракия' || this.district == 'Филипово' || this.district == 'Христо Смирненски' || this.district == 'Южен')) {
                this.addCosts = ''
            } else {
                this.addCosts = '+ транспортни разходи'
            }
        },
        district() {
            if(this.city == 'Пловдив' && (this.district == 'Център' || this.district == 'Беломорски' || this.district == 'Гагарин' || this.district == 'Захарна Фабрика' || this.district == 'Изгрев' || this.district == 'Каменица' || this.district == 'Коматево' || this.district == 'Киршияка' || this.district == 'Младежки хълм' || this.district == 'Остромила' || this.district == 'Прослав' || this.district == 'Столипиново' || this.district == 'Съдийски' || this.district == 'Тракия' || this.district == 'Филипово' || this.district == 'Христо Смирненски' || this.district == 'Южен')) {
                this.addCosts = ''
            } else {
                this.addCosts = '+ транспортни разходи'
            }
        }
    }
};
</script>