export const cities = [
    { name: 'Айтос', district: ['Център'] },
    { name: 'Аксаково', district: ['Център'] },
    { name: 'Алфатар', district: ['Център'] },
    { name: 'Антоново', district: ['Център'] },
    { name: 'Априлци', district: ['Център'] },
    { name: 'Ардино', district: ['Център'] },
    { name: 'Асеновград', district: ['Беделема', 'Горни Воден', 'Долни Воден', 'Запад', 'Изток', 'Свети Георги', 'Център'] },
    { name: 'Ахелой', district: ['Център'] },
    { name: 'Ахтопол', district: ['Център'] },
    { name: 'Балчик', district: ['Балик', 'Бели Скали', 'Васил Левски', 'Гарадока', 'Двореца', 'Кулака', 'Левски', 'Сборно Място', 'Център'] },
    { name: 'Банкя', district: ['Вердикал', 'Градоман', 'Иваняне', 'Михайлово', 'Център'] },
    { name: 'Банско', district: ['Глазно', 'Грамадето', 'Гуровица', 'Кошерината', 'Новият град', 'Парцалето', 'Св. Иван Рилски', 'Старият град', 'Страгите'] },
    { name: 'Баня', district: ['Център'] },
    { name: 'Батак', district: ['Център'] },
    { name: 'Батановци', district: ['Център'] },
    { name: 'Белене', district: ['Център'] },
    { name: 'Белица', district: ['Център'] },
    { name: 'Белово', district: ['Център'] },
    { name: 'Белоградчик', district: ['Център'] },
    { name: 'Белослав', district: ['Център'] },
    { name: 'Берковица', district: ['Център'] },
    { name: 'Благоевград', district: ['Център'] },
    { name: 'Бобов дол', district: ['Център'] },
    { name: 'Бобошево', district: ['Център'] },
    { name: 'Божурище', district: ['9ти Септември', 'Жилища', 'Лазур', 'Максим Горки', 'Пролет', 'Толева махала', 'Черна бара', 'Център'] },
    { name: 'Бойчиновци', district: ['Център'] },
    { name: 'Болярово', district: ['Център'] },
    { name: 'Борово', district: ['Център'] },
    { name: 'Ботевград', district: ['Васил Левски', 'Гробищен', 'Иван Вазов', 'Саранск', 'Център'] },
    { name: 'Брацигово', district: ['Център'] },
    { name: 'Брегово', district: ['Център'] },
    { name: 'Брезник', district: ['Център'] },
    { name: 'Брезово', district: ['Център'] },
    { name: 'Брусарци', district: ['Център'] },
    {
        name: 'Бургас',
        district: ['Акациите',
            'Банево',
            'Братя Миладинови (жилищен комплекс)',
            'Ветрен (квартал на Бургас)',
            'Възраждане (жилищен комплекс на Бургас)',
            'Горно Езерово',
            'Долно Езерово',
            'Зорница (жилищен комплекс на Бургас)',
            'Изгрев (жилищен комплекс на Бургас)',
            'Крайморие',
            'Лозово',
            'Меден рудник (жилищен комплекс)',
            'Петко Славейков (жилищен комплекс)',
            'Победа (квартал на Бургас)',
            'Рудник (квартал на Бургас)',
            'Сарафово',
            'Черно море (квартал на Бургас)',
            'Център'
        ]
    },
    { name: 'Бухово', district: ['Център'] },
    { name: 'Българово', district: ['Център'] },
    { name: 'Бяла', district: ['Център'] },
    { name: 'Бяла', district: ['Център'] },
    { name: 'Бяла Слатина', district: ['Калоян', 'Младост', 'Сладница', 'Сребреня', 'Център'] },
    { name: 'Бяла Черква', district: ['Център'] },
    { name: 'Варна', district: ['Аспарухово', 'Боровец', 'Бриз', 'Васил Левски', 'Виница', 'Владислав Варненчик', 'Възраждане 1', 'Въраждане 2', 'Възраждане 3', 'Галата', 'Гръцки Квартал', 'ЖП Гара', 'Изгрев', 'Кайсиева Градина', 'Колхозен Пазар', 'Младост 1', 'Младост 2', 'Победа', 'Пчелина', 'Траката', 'Тракия', 'Трошево', 'Цветен квартал', 'Център', 'Чайка'] },
    { name: 'Велики Преслав', district: ['Център'] },
    { name: 'Велико Търново', district: ['Акация', 'Асенов', 'Асенова махала', 'Бузлуджа', 'Варуша', 'Велико Търново хилс', 'Зона Б', 'Зона В', 'Картала', 'Колю Фичето', 'Света Гора', 'Чолаковци', 'Център'] },
    { name: 'Велинград', district: ['Каменица', 'Лъджене', 'Чепино', 'Център'] },
    { name: 'Ветово', district: ['Център'] },
    { name: 'Ветрен', district: ['Център'] },
    { name: 'Видин', district: ['Акджамия', 'Ал. Стамболийски', 'Баба Тонка', 'Бонония', 'Васил Левски', 'Вида', 'Възраждане', 'Гео Милев', 'Георги Бенковски', 'Калето', 'Крум Бъчваров', 'Панония', 'Петко Каравелов', 'Плиска', 'Стефан Стамболов', 'Строител', 'Съединение', 'Химик', 'Христо Ботев', 'Център'] },
    { name: 'Враца', district: ['103', '72', 'Бистрец', 'Ведомствен район', 'Вежен', 'Дъбмика', 'Кемера', 'Кулата', 'Медковец', 'Металург', 'Младост', 'Околчица', 'Подбалканска', 'Промишлена зона 1', 'Промишлена зона 2', 'Река Лева', 'Сениче', 'Студентски град', 'Химик', 'Център'] },
    { name: 'Вълчедръм', district: ['Център'] },
    { name: 'Вълчи дол', district: ['Център'] },
    { name: 'Върбица', district: ['Център'] },
    { name: 'Вършец', district: ['Заножене', 'Изток', 'Център'] },
    { name: 'Габрово', district: ['Център'] },
    { name: 'Генерал Тошево', district: ['Център'] },
    { name: 'Главиница', district: ['Център'] },
    { name: 'Глоджево', district: ['Център'] },
    { name: 'Годеч', district: ['Център'] },
    { name: 'Горна Оряховица', district: ['Гарата', 'Градска Градина', 'Захарни Заводи', 'Калтинец', 'Камънето', 'Пролет', 'Честово', 'Център'] },
    { name: 'Гоце Делчев', district: ['Център'] },
    { name: 'Грамада', district: ['Център'] },
    { name: 'Гулянци', district: ['Център'] },
    { name: 'Гурково', district: ['Център'] },
    { name: 'Гълъбово', district: ['Център'] },
    { name: 'Две могили', district: ['Център'] },
    { name: 'Дебелец', district: ['Център'] },
    { name: 'Девин', district: ['Център'] },
    { name: 'Девня', district: ['Център'] },
    { name: 'Джебел', district: ['Център'] },
    { name: 'Димитровград', district: ['Димитър Благоев', 'Дружба', 'Каменец', 'Раковски', 'Славянски', 'Христо Ботев', 'Център'] },
    { name: 'Димово', district: ['Център'] },
    { name: 'Добринище', district: ['Център'] },
    { name: 'Добрич', district: ['Балик', 'Военно поделение', 'Добротица', 'Дружба 1', 'Дружба 2', 'Дружба 3', 'Дружба 4', 'Иглика', 'Изгрев', 'Промишлена Зона Север', 'Промишлена Зона Запад', 'Рилци', 'Строител', 'Христо Ботев', 'Югоизток', 'Център'] },
    { name: 'Долна баня', district: ['Център'] },
    { name: 'Долна Митрополия', district: ['Център'] },
    { name: 'Долна Оряховица', district: ['Център'] },
    { name: 'Долни Дъбник', district: ['Център'] },
    { name: 'Долни чифлик', district: ['Център'] },
    { name: 'Доспат', district: ['Център'] },
    { name: 'Драгоман', district: ['Център'] },
    { name: 'Дряново', district: ['Център'] },
    { name: 'Дулово', district: ['Център'] },
    { name: 'Дунавци', district: ['Център'] },
    { name: 'Дупница', district: ['Аракчийски мост', 'Байкал', 'Бешико', 'Бистрица', 'Вароша', 'Васил Левски', 'Възраждане', 'Възход', 'Гиздова махала', 'Горна махала', 'Дупница', 'Елица', 'Иван Вазов', 'Изгрев', 'Каваклия', 'Кършия в.з.', 'Новата махала', 'Подина', 'Прогорно', 'Развесена Върба', 'Рилски Езера', 'Спартак', 'Химик', 'Христо Ботев', 'Южен ж.к.', 'Център'] },
    { name: 'Дългопол', district: ['Център'] },
    { name: 'Елена', district: ['Център'] },
    { name: 'Елин Пелин', district: ['Център'] },
    { name: 'Елхово', district: ['Център'] },
    { name: 'Етрополе', district: ['Вранащица', 'Елацита', 'кв. 13', 'кв.8', 'Пъндевица', 'Център'] },
    { name: 'Завет', district: ['Център'] },
    { name: 'Земен', district: ['Център'] },
    { name: 'Златарица', district: ['Център'] },
    { name: 'Златица', district: ['Център'] },
    { name: 'Златоград', district: ['Център'] },
    { name: 'Ивайловград', district: ['Център'] },
    { name: 'Игнатиево', district: ['Център'] },
    { name: 'Искър', district: ['Център'] },
    { name: 'Исперих', district: ['Център'] },
    { name: 'Ихтиман', district: ['Изток', 'Клисарци', 'Стипон', 'Център'] },
    { name: 'Каблешково', district: ['Център'] },
    { name: 'Каварна', district: ['Център'] },
    { name: 'Казанлък', district: ['Бузлуджа', 'Васил Левски', 'Изгрев', 'Изток', 'Кармен', 'Кулата', 'Манастрира', 'Новенски', 'Родина', 'Стадиона', 'Стефан Караджа', 'Тракия', 'Хаджи Димитър', 'Христо Ботев', 'Център'] },
    { name: 'Калофер', district: ['Център'] },
    { name: 'Камено', district: ['Център'] },
    { name: 'Каолиново', district: ['Център'] },
    { name: 'Карлово', district: ['Апостолова гора', 'Арапово', 'Бадемлика', 'Бангладеш', 'Бешбунар', 'Васил Левски', 'Военно Градче', 'Възрожденски', 'Възстанически', 'Изгрев', 'Опълченски', 'Полигона', 'Розова Долина', 'Стара Планина', 'Сучурум', 'Сушица', 'Център'] },
    { name: 'Карнобат', district: ['Център'] },
    { name: 'Каспичан', district: ['Център'] },
    { name: 'Кермен', district: ['Център'] },
    { name: 'Килифарево', district: ['Център'] },
    { name: 'Китен', district: ['Център'] },
    { name: 'Клисура', district: ['Център'] },
    { name: 'Кнежа', district: ['Вапцаров', 'Младост', 'Център'] },
    { name: 'Козлодуй', district: ['жк. 1', 'жк. 2', 'жк. 2а', 'жк. 3', 'Крушов баир', 'Център'] },
    { name: 'Койнаре', district: ['Център'] },
    { name: 'Копривщица', district: ['Център'] },
    { name: 'Костандово', district: ['Център'] },
    { name: 'Костенец', district: ['Център'] },
    { name: 'Костинброд', district: ['Захари Зограф', 'Захари Стоянов', 'Зора', 'Маслово', 'Шияковци', 'Център'] },
    { name: 'Котел', district: ['Център'] },
    { name: 'Кочериново', district: ['Център'] },
    { name: 'Кресна', district: ['Център'] },
    { name: 'Криводол', district: ['Център'] },
    { name: 'Кричим', district: ['Център'] },
    { name: 'Крумовград', district: ['Център'] },
    { name: 'Крън', district: ['Център'] },
    { name: 'Кубрат', district: ['Център'] },
    { name: 'Куклен', district: ['Център'] },
    { name: 'Кула', district: ['Център'] },
    { name: 'Кърджали', district: ['Център'] },
    { name: 'Кюстендил', district: ['Белия Камък', 'Бузлуджа', 'Въртешево', 'Георги Димитров', 'Герена', 'Запад', 'Изток', 'Илъо Войвода', 'Колуша', 'Могилата', 'Никола Въжаров', 'Осогово', 'Румена войвода', 'Христо Ботев', 'Център'] },
    { name: 'Левски', district: ['Център'] },
    { name: 'Летница', district: ['Център'] },
    { name: 'Ловеч', district: ['Вароша', 'Гозница', 'Дикисан', 'Дръстене', 'Здравец', 'Мелта', 'Младост', 'Продимчец', 'Скобелево', 'Сър пазар', 'Хармането', 'Червен бряг', 'Център'] },
    { name: 'Лозница', district: ['Център'] },
    { name: 'Лом', district: ['Боруна', 'Зорница', 'Кален поток', 'Калетата', 'Младеново', 'Момин брод', 'Садове', ' Хумата', 'Център'] },
    { name: 'Луковит', district: ['Изток', 'Център'] },
    { name: 'Лъки', district: ['Център'] },
    { name: 'Любимец', district: ['Център'] },
    { name: 'Лясковец', district: ['Център'] },
    { name: 'Мадан', district: ['Център'] },
    { name: 'Маджарово', district: ['Център'] },
    { name: 'Малко Търново', district: ['Център'] },
    { name: 'Мартен', district: ['Център'] },
    { name: 'Мездра', district: ['Боденец', 'Брусен', 'Долна Кремена', 'Дърманци', 'Западна индустриална зона', 'Източна индустриална зона', 'Камъка', 'Кременска рампа', 'Крета', 'Лещака', 'Моравица', 'Принчовец', 'Руска Бела', 'Център'] },
    { name: 'Мелник', district: ['Център'] },
    { name: 'Меричлери', district: ['Център'] },
    { name: 'Мизия', district: ['Лазар Драганов', 'Център'] },
    { name: 'Момин проход', district: ['Център'] },
    { name: 'Момчилград', district: ['Център'] },
    { name: 'Монтана', district: ['Жерави', 'Жеравица', 'Изгрев', 'Кошарник', 'Мала Кутловица', 'Младост - 1', 'Младост - 2', 'Огоста', 'Парта - 1', 'Парта - 2', 'Плиска', 'Пъстрина', 'Център'] },
    { name: 'Мъглиж', district: ['Център'] },
    { name: 'Неделино', district: ['Център'] },
    { name: 'Несебър', district: ['Аурелия', 'Младост', 'Стар град', 'Център', 'Черно Море'] },
    { name: 'Николаево', district: ['Център'] },
    { name: 'Никопол', district: ['Център'] },
    { name: 'Нова Загора', district: ['Загоре', 'Тракия', 'Център', 'Шести'] },
    { name: 'Нови Искър', district: ['Гниляне', 'ж.к. Военни жилища', 'Изток', 'Кумарица', 'Курило', 'Промишлена зона Славовци', 'Славовци', 'Център'] },
    { name: 'Нови пазар', district: ['Център'] },
    { name: 'Обзор', district: ['Център'] },
    { name: 'Омуртаг', district: ['Център'] },
    { name: 'Опака', district: ['Център'] },
    { name: 'Оряхово', district: ['Христо Икономов', 'Център'] },
    { name: 'Павел баня', district: ['Център'] },
    { name: 'Павликени', district: ['Център'] },
    { name: 'Пазарджик', district: ['Бесапара', 'Вароша', 'Димчо Дебелянов', 'Запад', 'Изток', 'Индустриална Зона', 'Манастири', 'Марица', 'Младост', 'Писковец', 'Поликлиниката', 'Ставропол', 'Стадиона', 'Устрем', 'Център', 'Чиксалън', 'Широк Център', 'Ябълките'] },
    { name: 'Панагюрище', district: ['Драгулин', 'Дринча', 'Мареш', 'Оптиелектрон', 'Петровден', 'Център'] },
    { name: 'Перник', district: ['Байкушева махала', 'Бела Вода', 'Бели брег', 'Варош', 'Васил Левски', 'Даскалово', 'Димова махала', 'Драгановец', 'Захаринова махала', 'Изток', 'Илчова махала', 'Калкас', 'Каменица', 'Караманица', 'Клепало', 'Кошаревска махала', 'Красно село', 'Кристал', 'Куциян', 'Ладовица', 'Могиличе', 'Монте Карло', 'Мошино', 'Нов Драгановец', 'Парлева махала', 'Пригаров район', 'Промишлена зона', 'Проучване', 'Радина чешма', 'Ралица', 'Рено', 'Рудничар', 'Селото', 'Стара Тева', 'Табана', 'Твърди Ливади', 'Тева', 'Христо Ботев', 'Христо Смирненски', 'Хумни дол', 'Църква', 'Център', 'Чишарева махала', 'Чумачка махала'] },
    { name: 'Перущица', district: ['Център'] },
    { name: 'Петрич', district: ['Център'] },
    { name: 'Пещера', district: ['Гробищен', 'Едиверен', 'Изгрев', 'Луковица', 'Център'] },
    { name: 'Пирдоп', district: ['Център'] },
    { name: 'Плачковци', district: ['Център'] },
    { name: 'Плевен', district: ['Център'] },
    { name: 'Плиска', district: ['Център'] },
    { name: 'Пловдив', district: ['Белащица', 'Беломорски', 'Бенковски', 'Браниполе', 'Брестник', 'Войводино', 'Гаганица', 'Гагарин', 'Захарна Фабрика', 'Златитрап', 'Изгрев', 'Кадиево', 'Каменица', 'Коматево', 'Костиево', 'Крумово', 'Кършияка', 'Марково', 'Младежки хълм', 'Оризари', 'Остромила', 'Прослав', 'Първенец', 'Радиново', 'Рогош', 'Скутаре', 'Столипиново', 'Съдийски', 'Тракия', 'Филипово', 'Христо Смирненски', 'Център', 'Южен'] },
    { name: 'Полски Тръмбеш', district: ['Център'] },
    { name: 'Поморие', district: ['Свети Георги', 'Свобода', 'Център'] },
    { name: 'Попово', district: ['Център'] },
    { name: 'Пордим', district: ['Център'] },
    { name: 'Правец', district: ['Живковска', 'Манастирица', 'Манастирица 2', 'Никовци', 'Север', 'Симидарското', 'Скърнава', 'Център'] },
    { name: 'Приморско', district: ['Център'] },
    { name: 'Провадия', district: ['Център'] },
    { name: 'Първомай', district: ['Център'] },
    { name: 'Раднево', district: ['Гаров квартал', 'Гипсово', 'Кантона', 'Център', 'Юг'] },
    { name: 'Радомир', district: ['Център'] },
    { name: 'Разград', district: ['Център'] },
    { name: 'Разлог', district: ['Васил Левски', 'Изток', 'КЦХ', 'Предел', 'Саровица', 'Център'] },
    { name: 'Ракитово', district: ['Център'] },
    { name: 'Раковски', district: ['Генерал Николаево', 'Пърчевич', 'Секирово', 'Център'] },
    { name: 'Рила', district: ['Център'] },
    { name: 'Роман', district: ['Център'] },
    { name: 'Рудозем', district: ['Център'] },
    { name: 'Русе', district: ['Веждата', 'Възраждане', 'Възраждане Юг', 'Долапите', 'Дружба 1', 'Дружба 2', 'Дружба 3', 'Западна промишлена зона', 'Захарна Фабрика', 'Здравец', 'Здравец изток', 'Здравец север', 'Източна промишлена зона', 'Малъовица', 'Мидия-Енос', 'Новата махала', 'Родина 1', 'Родина 2', 'Родина 3', 'Родина 4', 'Сарая', 'Средна Кула', 'Тракция', 'Хъшове', 'Цветница', 'Централен Южен район', 'Център', 'Чародейка Север', 'Чародейка Юг', 'Ялта'] },
    { name: 'Садово', district: ['Център'] },
    { name: 'Самоков', district: ['Възраждане', 'Герена', 'Елица', 'Самоково', 'Център'] },
    { name: 'Сандански', district: ['Зора', 'Изгрев', 'Изток', 'Поленица', 'Спартак', 'Център'] },
    { name: 'Сапарева баня', district: ['Център'] },
    { name: 'Свети Влас', district: ['Инцараки', 'Русалка', 'Център', 'Юрта'] },
    { name: 'Свиленград', district: ['Център'] },
    { name: 'Свищов', district: ['Център'] },
    { name: 'Своге', district: ['Алилица', 'Бобковица', 'Брезов рът', 'Джиджовица', 'Дренов', 'Козарника', 'Ранъов дол', 'Репище', 'Ръждевлак', 'Светица', 'Старо село', 'Център', 'Яничица'] },
    { name: 'Севлиево', district: ['Център'] },
    { name: 'Сеново', district: ['Център'] },
    { name: 'Септември', district: ['Център'] },
    { name: 'Силистра', district: ['Изток', 'Кючук Мустафа', 'Малчика', 'Самолета', 'Триъгълниците', 'Център', 'Юг'] },
    { name: 'Симеоновград', district: ['Център'] },
    { name: 'Симитли', district: ['Център'] },
    { name: 'Славяново', district: ['Център'] },
    { name: 'Сливен', district: ['Българка', 'Даме Груев', 'Дебелата Курия', 'Дружба', 'Звездите', 'Клуцохор', 'Колъо Фичето', 'Комлука', 'Младост', 'Надежда', 'Ново Село', 'Република', 'Речица', 'Руски Квартал', 'Сини Камъни', 'Слънчев Бряг', 'Стоян Заимов', 'Център'] },
    { name: 'Сливница', district: ['Център'] },
    { name: 'Сливо поле', district: ['Център'] },
    { name: 'Смолян', district: ['Център'] },
    { name: 'Смядово', district: ['Център'] },
    { name: 'Созопол', district: ['Буджака', 'Мисаря', 'Нов Град', 'Созополис', 'Стар Град', 'Център'] },
    { name: 'Сопот', district: ['Сарая', 'Център'] },
    {
        name: 'София',
        district: ['Аерогарата',
            'Бакърена ф',
            'Банишора ',
            'Бели Брези ',
            'Беловодски Път ',
            'Бенковски ',
            'Бистрица ',
            'Божурище ',
            'Борово ',
            'Ботунец ',
            'Бояна ',
            'Бул. България ',
            'Бункера ',
            'Бъкстон ',
            'Витоша ',
            'Военна Рампа ',
            'Враждебна ',
            'Враня ',
            'Връбница ',
            'Гара Искър ',
            'Гевгелийски ',
            'Гео Милев ',
            'Гниляне ',
            'Горна Баня ',
            'Горубляне ',
            'Гоце Делчев ',
            'Дианабад ',
            'Дим.Миленков ',
            'Драгалевци ',
            'Дружба 1 ',
            'Дружба 2 ',
            'Дървеница ',
            'Западен Парк ',
            'Захарна Фабрика ',
            'Зона Б 18 ',
            'Зона Б 19 ',
            'Зона Б 5 ',
            'Иван Вазов ',
            'Изгрев ',
            'Изток ',
            'Илинден ',
            'Илиянци ',
            'ИХТИМАН ',
            'Килиите ',
            'Киноцентър ',
            'Княжево ',
            'Красна Поляна ',
            'Красно Село ',
            'Кремиковци ',
            'Кръстова Вада ',
            'Лагера ',
            'Левски ',
            'Левски В ',
            'Левски Г ',
            'Лозенец ',
            'Люлин 1 ',
            'Люлин 10 ',
            'Люлин 2 ',
            'Люлин 3 ',
            'Люлин 4 ',
            'Люлин 5 ',
            'Люлин 6 ',
            'Люлин 7 ',
            'Люлин 8 ',
            'Люлин 9 ',
            'Люлин център ',
            'Малашевци ',
            'Малинова Долина ',
            'Манастирски Ливади ',
            'Мед.академия ',
            'Младост 1 ',
            'Младост 1А ',
            'Младост 2 ',
            'Младост 3 ',
            'Младост 4 ',
            'Мод.предградие ',
            'Мотописта ',
            'Мусагеница ',
            'Надежда 1 ',
            'Надежда 2 ',
            'Надежда 3 ',
            'Надежда 4 ',
            'Надежда 5 ',
            'Надежда 6 ',
            'Обеля ',
            'Обеля 1 ',
            'Обеля 2 ',
            'Оборище ',
            'Овча Купел ',
            'Овча Купел 1 ',
            'Овча Купел 2 ',
            'Орландовци ',
            'Павлово ',
            'Панчарево ',
            'Подуяне ',
            'Полигона ',
            'Разсадника ',
            'Редута ',
            'Република ',
            'Света Троица ',
            'Свобода ',
            'Сердика ',
            'Сеславци ',
            'Симеоново ',
            'Славия ',
            'Слатина ',
            'Стефан Караджа ',
            'Стрелбище ',
            'Студентски Град ',
            'Сухата Река ',
            'Суходол ',
            'Толстой ',
            'Требич ',
            'Триъгълника ',
            'ул. Оборище ',
            'Факултета ',
            'Филиповци ',
            'Фондови Жилища ',
            'Хаджи Димитър ',
            'Хиподрума ',
            'Хладилника ',
            'Христо Ботев ',
            'Централна Гара ',
            'Център ',
            'Челопечене ',
            'Чепинци ',
            'Яворов ',
        ]
    },
    { name: 'Средец', district: ['Център'] },
    { name: 'Стамболийски', district: ['Център'] },
    { name: 'Стара Загора', district: ['Аязмото', 'Беш Бунар', 'Била', 'Голеш', 'Дъбрава', 'Железник - изток', 'Железник - център', 'Загорка', 'Зора', 'Индустриална зона - запад', 'Индустриална зона - изток', 'Казански', 'Казански - изток', 'Колъо Ганчев', 'Лозенец', 'Македонски', 'МБАЛ Иван Рилски', 'Метро', 'Никола Петков', 'Операта', 'Опълченски', 'ОРБ', 'Ремиза', 'Самара 1', 'Самара 2', 'Самара 3', 'Стидентско градче', 'Чучура - север', 'Чучура - център', 'Чучура - юг', 'Център'] },
    { name: 'Стражица', district: ['Център'] },
    { name: 'Стралджа', district: ['Център'] },
    { name: 'Стрелча', district: ['Център'] },
    { name: 'Суворово', district: ['Център'] },
    { name: 'Сунгурларе', district: ['Център'] },
    { name: 'Сухиндол', district: ['Център'] },
    { name: 'Съединение', district: ['Център'] },
    { name: 'Сърница', district: ['Център'] },
    { name: 'Твърдица', district: ['Център'] },
    { name: 'Тервел', district: ['Център'] },
    { name: 'Тетевен', district: ['Гробищен', 'Обединение', 'Пеново', 'Център'] },
    { name: 'Тополовград', district: ['Център'] },
    { name: 'Троян', district: ['Център'] },
    { name: 'Трън', district: ['Център'] },
    { name: 'Тръстеник', district: ['Център'] },
    { name: 'Трявна', district: ['Център'] },
    { name: 'Тутракан', district: ['Център'] },
    { name: 'Търговище', district: ['Център'] },
    { name: 'Угърчин', district: ['Център'] },
    { name: 'Хаджидимово', district: ['Център'] },
    { name: 'Харманли', district: ['Център'] },
    { name: 'Хасково', district: ['Болярово', 'Гара Движение', 'Дружба 1', 'Западна промишлена зона', 'Изгрев', 'Източна промишлена зона', 'Каменни', 'Кенана', 'Любен Каравелов', 'Македонски', 'Овчарски', 'Орфей', 'Поройни', 'Република', 'Северна промишлена зона', 'Тракийски', 'Укрепленията', 'Хисаря', 'Червената Стена', 'Южна промишлена зона', 'Ямача', 'Център'] },
    { name: 'Хисаря', district: ['Веригово', 'Миромир', 'Момина баня', 'Орфей', 'Център'] },
    { name: 'Цар Калоян', district: ['Център'] },
    { name: 'Царево', district: ['Център'] },
    { name: 'Чепеларе', district: ['Център'] },
    { name: 'Червен бряг', district: ['Център'] },
    { name: 'Черноморец', district: ['Център'] },
    { name: 'Чипровци', district: ['Център'] },
    { name: 'Чирпан', district: ['Градски', 'Младост', 'Памуклука', 'Център', 'Черно море'] },
    { name: 'Шабла', district: ['Център'] },
    { name: 'Шивачево', district: ['Център'] },
    { name: 'Шипка', district: ['Център'] },
    { name: 'Шумен', district: ['Център'] },
    { name: 'Ябланица', district: ['Център'] },
    { name: 'Якоруда', district: ['Център'] },
    { name: 'Ямбол', district: ['Аврен', 'Васил Левски', 'Владимир Заимов', 'Възраждане', 'Граф Игнатиев', 'Георги Бенковски', 'д-р Димитър Дончев', 'Диана', 'Златен Рог', 'Зорница', 'Изток', 'Каргон', 'Промишлена Зона', 'Райна Княгиня', 'Хале', 'Център'] },

]